<template>
  <d-edit-card
    ref="editForm"
    :entity="form"
    :submit-function="submitForm"
    card-title="Create pre-payment"
    submit-success-title="Pre-payment successfully created!"
    redirect-after-creation="BalanceList"
  >
    <template #default="{ data }">
      <q-card-section class="col">
        <div class="row">
          <div class="col-xs-12 col-md-6 q-col-gutter-md">
            <d-user-select
              emit-value
              map-options
              :dense="$q.screen.lt.md"
              v-model="data.agentId"
              :options="agentsDictionary"
              label="Agent"
              class="col-12"
              :multiple="false"
              :rules="[rules.notNullField]"
              hint="Required field*"
            />
            <div class="row col-12 q-gutter-sm">
              <q-select
                emit-value
                map-options
                :dense="$q.screen.lt.md"
                :options="currencyDictionary"
                v-model="data.currencyId"
                outlined
                label="Currency"
                class="col-3"
                :rules="[rules.notNullField]"
                hint="Required field*"
                option-value="id"
                option-label="name"
              />
              <q-input
                :dense="$q.screen.lt.md"
                v-model.number="data.amount"
                outlined
                type="number"
                label="Amount"
                step="any"
                class="col-grow"
                :rules="[rules.notNullField]"
                hint="Required field*"
              />
            </div>
            <div class="row col-12 q-gutter-sm">
              <q-input
                :dense="$q.screen.lt.md"
                v-model.number="data.prepaymentPercent"
                outlined
                step="any"
                type="number"
                label="Settlement (%)"
                class="col-6"
              />
              <q-input
                :dense="$q.screen.lt.md"
                v-model.number="data.finalAmount"
                outlined
                type="number"
                label="Final amount"
                class="col-grow"
                readonly
              />
            </div>
            <q-input
              :dense="$q.screen.lt.md"
              v-model.number="data.comment"
              outlined
              type="textarea"
              label="Comment"
              class="col-12"
            />
          </div>
        </div>
      </q-card-section>
    </template>
    <template #actions="{ loading }">
      <d-edit-form-actions :disable="loading" />
    </template>
  </d-edit-card>
</template>

<script>
import { DEditFormActions } from '@/features/edit-form-actions';
import DUserSelect from '@/features/user-select';
import DEditCard from '@/layouts/edit-card';
import {
  currencyController,
  prepaymentController,
  usersController,
} from '@/shared/api';
import { authUtils, notify } from '@/shared/utils';
import { debounce } from 'quasar';

export default {
  components: { DEditCard, DEditFormActions, DUserSelect },
  data: () => ({
    currencyDictionary: [],
    agentsDictionary: [],
    form: {
      agentId: null,
      currencyId: null,
      amount: null,
      prepaymentPercent: null,
      finalAmount: null,
      comment: '',
    },
    rules: {
      notNullField: (val) => val !== null || 'Please type value',
    },
  }),
  created() {
    const debouncedPreviewPrepayment = debounce(async () => {
      const { agentId, currencyId, amount, prepaymentPercent, comment } =
        this.form;

      if (!agentId || !currencyId || !amount || this.$refs.editForm.loading) {
        return;
      }

      try {
        this.$refs.editForm.setLoading(true);

        const { data } = await prepaymentController(
          authUtils.getRoleByHierarchy()
        ).previewPrepayment({
          agentId,
          currencyId,
          amount,
          prepaymentPercent: prepaymentPercent ?? null,
          comment: comment.toString().trim(),
        });

        this.form.finalAmount = data.finalAmount;

        this.form.prepaymentPercent = Number.isFinite(+data.prepaymentPercent)
          ? +data.prepaymentPercent
          : this.form.prepaymentPercent;

        await this.$nextTick();

        debouncedPreviewPrepayment.cancel();
      } catch (e) {
        notify.error(e.message);
      } finally {
        this.$refs.editForm.setLoading(false);
      }
    }, 700);

    this.$watch(
      () => {
        return [
          this.form.agentId,
          this.form.currencyId,
          this.form.amount,
          this.form.prepaymentPercent,
          this.form.comment.toString().trim().length,
        ].join('-');
      },
      () => {
        debouncedPreviewPrepayment();
      },
      {
        immediate: true,
      }
    );

    this.$once('hook:beforeDestroy', () => {
      debouncedPreviewPrepayment.cancel();
    });
  },
  async mounted() {
    try {
      this.$refs.editForm.setLoading(true);
      const primaryRole = authUtils.getRoleByHierarchy();
      const [currencyResponse, agentsResponse] = await Promise.all([
        currencyController(primaryRole).getCurrency(),
        usersController(primaryRole).agentsForPrepayments(),
      ]);

      this.currencyDictionary = currencyResponse.data;
      this.agentsDictionary = agentsResponse.data;
    } catch (e) {
      notify.error('Dictionary loading error');
    } finally {
      this.$refs.editForm.setLoading(false);
    }
  },
  methods: {
    async submitForm() {
      this.form.prepaymentPercent = this.form.prepaymentPercent ?? null;

      return prepaymentController(
        authUtils.getRoleByHierarchy()
      ).createPrepayment(this.form);
    },
  },
};
</script>
